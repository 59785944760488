
import { defineComponent } from '@vue/composition-api'
import { mapActions, mapGetters, mapState } from 'vuex'
import { format, parseISO } from 'date-fns'
import frLocale from 'date-fns/locale/fr'

interface TrainingSession {
  '@id': string;
  logisticSessions: {
    startAt: string
  }[]
}

interface NextThreeMonths {
  logisticSessionId: string;
  month: string;
}

export default defineComponent({
  name: 'TrainerSpace',
  components: {
  },
  computed: {
    ...mapState('trainerSpace', {
      trainingSessions: 'trainingSessions',
      loading: 'loading',
    }),
    ...mapGetters('user', {
      userConnectedIsTrainerRegionManager: 'isTrainerRegionManager',
    }),
    nextThreeMonths () {
      const occurrencesByMonth: NextThreeMonths[] = []

      this.trainingSessions.forEach((trainingSession : any) => {
        trainingSession.logisticSessions.forEach((logisticSession: any) => {
          if (logisticSession.startAt) {
            const startAt = parseISO(logisticSession.startAt)
            const month : string = format(startAt, 'LLLL', { locale: frLocale })
            const monthNumber : number = parseInt(format(startAt, 'M', { locale: frLocale }))

            if (!occurrencesByMonth[monthNumber]) {
              occurrencesByMonth[monthNumber] = {
                logisticSessionId: logisticSession['@id'].replaceAll('/', ''),
                month: month,
              }
            }
          }
        })
      })

      const sortedMonths = Object.keys(occurrencesByMonth)
        .map(monthNumber => parseInt(monthNumber))
        .sort((a, b) => a - b)
      const nextThreeMonths = sortedMonths.slice(0, 3)

      return nextThreeMonths.map(month => occurrencesByMonth[month])
    },
  },
  methods: {
    format,
    ...mapActions('trainerSpace', {
      loadTrainingSessions: 'loadTrainingSessions',
    }),
    formatDay (date: string) {
      let formattedDate = ''
      if (date) {
        formattedDate = format(new Date(date), 'EEE dd MMM yyyy', {
          locale: frLocale,
        })
      }
      return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
    },
    formatTime (date: string) {
      return format(new Date(date), "H'h'mm'", {
        locale: frLocale,
      })
    },
    formatMonth (date: string) {
      return format(new Date(date), 'LLLL', {
        locale: frLocale,
      })
    },
    handleTrainingSessionClick (trainingSession: any, indexLogisticSession: any) {
      if (this.dialog) {
        // Prevent click on another training session while a dialog is open
        return
      }
      this.trainerSessionSelected = trainingSession
      this.indexLogisticSessionSelected = indexLogisticSession
      this.dialog = true
    },
    getUrlToDownloadTrainingSessionPlanning (id: string|number): string {
      const apiUrl = process.env.NODE_ENV === 'development' ? `${process.env.VUE_APP_BACKEND_BASE_URL}` : '/'

      return apiUrl + 'api/training_sessions/' + id + '/training_planning_sharepoint_file'
    },
  },
  created () {
    this.loadTrainingSessions()
  },
  data () {
    return {
      trainerSessionSelected: null,
      indexLogisticSessionSelected: null,
      dialog: false,
    }
  },
})
